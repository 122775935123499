import { DimensionSet } from '@core/metrics/types';

export enum Operation {
  // Stream Workflow
  Negotiation = 'Negotiation',
  Registration = 'Registration',
  Hello = 'Hello',
  GetAuthInfo = 'GetAuthInfo',
  WDPageLoad = 'WDPageLoad',
  Authenticate = 'Authenticate',
  GetResources = 'GetResources',
  StartConnect = 'StartConnect',
  Connect = 'Connect',
  ConnectAttempt = 'ConnectAttempt',
  SessionProvision = 'SessionProvision',
  SSIG = 'SSIG',
  Streaming = 'Streaming',
  Session = 'Session',
  // Todo: Need to Refactor
  PerformResourceAction = 'PerformResourceAction',

  // Auth
  WdResourceLoad = 'WdResourceLoad',
  WdUserAuth = 'WdUserAuth',
  SSOUserAuth = 'SSOUserAuth',
  SSOAuthPipeClientConnect = 'SSOAuthPipeClientConnect',
  SSOAuthBringToFront = 'SSOAuthBringToFront',
  DeviceAuth = 'DeviceAuth',
  AuthContextRequest = 'AuthContextRequest',

  // Stream
  StreamContextRequest = 'StreamContextRequest',
  StreamRequest = 'StreamRequest',

  // Misc
  Application = 'Application',
  MenuClick = 'MenuClick',
  GetHeartbeatInfo = 'GetHeartbeatInfo',
  ReportHeartbeat = 'ReportHeartbeat',
  Heartbeat = 'Heartbeat',
  HealthCheck = 'HealthCheck',
  ChangeCompute = 'ChangeComputeType',
  IncreaseVolume = 'IncreaseVolumeSize',
  ModifyRunningMode = 'ModifyRunningMode',
  Reboot = 'Reboot',
  Rebuild = 'Rebuild',
  CghConnection = 'CghConnection',
  WspPluginDeinit = 'WspPluginDeinit',
  WspCleanup = 'WspCleanup',
  LocaleUpdate = 'LocaleUpdate',
  ErrorUpdate = 'ErrorUpdate',
  DnsLookup = 'DnsLookup',
  ConnectionAlias = 'ConnectionAlias',
  SmartCardCertificateSelect = 'SmartCardCertificateSelect',
  WorkSpacesStatus = 'WorkSpacesStatus',
  AuthProcessPipeConnect = 'AuthProcessPipeConnect',
  AuthProcessParentVerify = 'AuthProcessParentVerify',
  DescribeClientAddIns = 'DescribeClientAddIns',
  LaunchClientAddIn = 'LaunchClientAddIn',
  CloseClientAddIn = 'CloseClientAddIn',
  AudioInStart = 'AudioInStart',
  AudioInStop = 'AudioInStop',
  DescribeClientCustomization = 'DescribeClientCustomization',
  DiagnosticsLogUploadProcessStartUp = 'DiagnosticsLogUploadProcessStartUp',
  DiagnosticsLogUploadProcessValidation = 'DiagnosticsLogUploadProcessValidation',
  MigrateRegistrationCodes = 'MigrateRegistrationCodes',
  DisplayWarpDriveURL = 'DisplayWarpDriveURL',
  BrokerAuthenticationResult = 'BrokerAuthenticationResult',
  // RTC
  BrowserRTCPostMessage = 'BrowserRTCPostMessage',
  BrowserRTCOnMessage = 'BrowserRTCOnMessage',
  SettingsRTCUpdate = 'SettingsRTCUpdate',
  MetricPublishRequest = 'MetricPublishRequest',

  // Solo
  SoloUrlLoadRequest = 'SoloUrlLoadRequest',

  // IPV6
  IPv6Fallback = 'IPv6Fallback',
}

export enum MetricName {
  Success = 'Success',
  Error = 'Error',
  Fault = 'Fault',
  OpTime = 'OpTime',

  // Special metrics only for PerformResourceActionConnect
  StartAttempts = 'StartAttempts',
  Cancel = 'Cancel',

  // Application metrics
  Launch = 'Launch',
  UriTrigger = 'UriTrigger',
  UriTriggerRegCode = 'UriTriggerRegCode',
  UriTriggerUsername = 'UriTriggerUsername',
  UriTriggerMfa = 'UriTriggerMfa',
  LanguageChange = 'LanguageChange',
  Crash = 'Crash',
  UnhandledException = 'UnhandledException',
  RememberMe = 'RememberMe',
  SavedRegistration = 'SavedRegistration',
  JsonException = 'JsonException',
  cursorDataException = 'cursorDataException',
  UnSupportedProtocolOnDevice = 'UnSupportedProtocolOnDevice',
  // WDPage
  WdResourceLoadError = 'WdResourceLoadError',

  // MenuClick metrics
  NewRegistration = 'NewRegistration',
  AboutWorkSpaces = 'AboutWorkSpaces',
  DisconnectWorkSpace = 'DisconnectWorkSpace',
  RestartWorkSpace = 'RestartWorkSpace',
  EnterFullScreen = 'EnterFullScreen',
  SendCtrlAltDelete = 'SendCtrlAltDelete',
  ManageLogin = 'ManageLogin',
  ManageProxy = 'ManageProxy',
  ChangeHardwareAcceleration = 'ChangeHardwareAcceleration',
  ChangeLanguage = 'ChangeLanguage',
  ChangeComputeType = 'ChangeComputeType',
  ChangeDiskSize = 'ChangeDiskSize',
  SwitchRunningMode = 'SwitchRunningMode',
  RebuildWorkSpace = 'RebuildWorkSpace',
  CheckNetworkHealth = 'CheckNetworkHealth',
  AboutMyWorkSpace = 'AboutMyWorkSpace',
  SendFeedback = 'SendFeedback',
  DeviceRedirection = 'DeviceRedirection',
  StreamingSettings = 'StreamingSettings',

  // Session Metrics
  LoginWithStartTime = 'LoginWithStartTime',
  LoginTime = 'LoginTime',
  Disconnect = 'Disconnect',
  UsageTime = 'UsageTime',
  SessionRtt = 'SessionRtTime',
  LoginWithStartTimeSmartCard = 'LoginWithStartTimeSmartCard',
  LoginTimeSmartCard = 'LoginTimeSmartCard',

  // Health Check metrics
  HcNetworkError = 'NetworkError',
  HcInternetError = 'InternetError',
  HcBrokerError = 'BrokerError',
  HcTcpError = 'TcpError',
  HcUdpError = 'UdpError',
  HcRtt = 'SsgRtTime',
  HcUnhealthy = 'Unhealthy',

  // RemoteConfig related metrics
  RemoteConfigLocaleUpdated = 'RemoteConfigLocaleUpdated',
  RemoteConfigErrorsUpdated = 'RemoteConfigErrorsUpdated',

  // DR related metrics
  NumEntries = 'NumEntries',

  // WorkSpaces status related metrics
  WorkSpacesStatusError = 'WorkSpacesStatusError',

  // WorkSpaces Windows CEF-fix metrics
  LaunchAuthProcess = 'LaunchAuthProcess',
  AuthProcessAbruptExit = 'AuthProcessAbruptExit',

  // New disconnect metric
  WorkspaceDisconnect = 'WorkspaceDisconnect',

  // Client AddIns
  ClientAddInBrowserNotAvailable = 'ClientAddInBrowserNotAvailable',

  // Solo Url Allow listing
  SoloUrlLoadRequestBlocked = 'SoloUrlLoadRequestBlocked',
  SoloUrlLoadRequestComplete = 'SoloUrlLoadRequestComplete',

  // Fallback Metrics
  AllocateResourceFallback = 'AllocateResourceFallback',
  IPv6Fallback = 'IPv6Fallback',
}

export enum Dimension {
  AppName = 'Program',
  Region = 'Marketplace',
  Operation = 'Operation',
  Version = 'Instance',
  DeviceTime = 'DeviceTime',
  DeviceId = 'DeviceId',
  AppSessionId = 'AppSessionId',
  OrgName = 'OrgName',
  DirectoryId = 'DirectoryId',
  SessionId = 'SessionId',
  ResourceId = 'ResourceId',
  Vendor = 'Vendor',
  Model = 'Model',
  Memory = 'Memory',
  Cpu = 'Cpu',
  Gpu = 'Gpu',
  HostOs = 'HostOS',
  Display = 'Displays',
  HostOsVersion = 'HostOSVersion',
  NetworkType = 'NetworkType',
  ScreenSize = 'ScreenSize',
  Reason = 'Reason',
  Reconnect = 'Reconnect',
  ProtocolName = 'ProtocolName',
  PluginName = 'PluginName',
  ExceptionType = 'ExceptionType',
  ExceptionMessage = 'ExceptionMessage',
  ExceptionSource = 'ExceptionSource',
  ExceptionMethodName = 'ExceptionMethodName',
  ExceptionFileName = 'ExceptionFileName',
  ExceptionFileLineNumber = 'ExceptionFileLineNumber',
  ExceptionFileColumnNumber = 'ExceptionFileColumnNumber',
  ExceptionStackTrace = 'ExceptionStackTrace',
  DnsResult = 'DnsValue',
  DnsQuery = 'DnsQuery',
  Url = 'Url',
  UrlLoadTime = 'UrlLoadTime',
  Method = 'Method',
  ErrorCode = 'ErrorCode',
  ProxyType = 'ProxyType',
  LoginType = 'LoginType',
  InnerExceptionType = 'InnerExceptionType',
  InnerExceptionMessage = 'InnerExceptionMessage',
  Is64BitClient = 'Is64BitClient',
  RegCode = 'RegCode',
  NumberOfAddIns = 'NumberOfAddIns',
  AddInName = 'AddInName',
  AddInURL = 'AddInURL',
  IsAutomatic = 'IsAutomatic',
  ConnectionStep = 'ConnectionStep',
  Count = 'Count',
  ProtocolDisconnectCode = 'ProtocolDisconnectCode',
  AppDisconnectAction = 'AppDisconnectAction',
  ProtocolVersion = 'ProtocolVersion',
  ResourceType = 'ResourceType',
  PlatformName = 'PlatformName',
  PlatformVersion = 'PlatformVersion',
  AuthProvider = 'AuthProvider',
  // Newly add dimension for CRR unknown to Diagnostics
  NumEntries = 'NumEntries',

  // Solo related field
  IsSoloClient = 'IsSoloClient',

  // PWD ReArch
  SUPPORTS_BP_V2 = 'SUPPORTS_BP_V2',
  USES_BP_V2 = 'USES_BP_V2',
}

export enum MetricResult {
  Success,
  Error,
  Fault,
  UnhandledException,
}

export interface IMetricOperation {
  operation: Operation;
  metricName: MetricName;
  dimensions: DimensionSet;
  result: MetricResult;
  getValue: () => number;
}

export interface ITimedMetricOperation extends IMetricOperation {
  startTimer: () => void;
  getStartTime: () => number | undefined;
}

export interface ITimedMetricOperationSummary {
  operation: Operation;
  metricName: MetricName;
  dimensions: DimensionSet;
  result: MetricResult;
  startTime: number | undefined;
}

export const STREAMING_ON = 'STREAMING_ON';
export enum STREAMING_ON_OPTIONS {
  ON = '1',
  OFF = '0',
}

export interface DCVInSessionMetricsConfigInput {
  platformAppName: string;
  region: string;
  endPoint: string;
  productVersion: string;
  deviceUUID: string;
  hostOS: string;
  hostOSVersion: string;
  proxyType: string;
  is64BitClient: boolean;
  sessionId: string;
  orgName: string;
  protocolName: string;
  directoryId: string;
  resourceId: string;
  loginType: string;
  resourceType: string;
  authProvider: string;
  sessionTimedMetricSummary?: ITimedMetricOperationSummary;
}
