import { useEffect } from 'react';
import { useSessionStore } from '@/stores/session';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import ConnectStatus from '@/components/ConnectStatus';
import { useLogUploaderPreferenceStore } from '@stores/loguploader';
import { buildWebDcvInSessionConfigInput } from '@views/Stream/utils';

interface StreamingProps {}

const logger = CoreFactory.getLogger();

const Maxibon: React.FC<StreamingProps> = () => {
  const sessionContext = useSessionStore(
    (state) => state.resourceSessionContext
  );
  const resourceId = useSessionStore((state) => state.resourceId);
  const resourceName = useSessionStore((state) => state.resourceName);
  const resourceComputeType = useSessionStore(
    (state) => state.resourceComputeType
  );
  const resourceIp = useSessionStore((state) => state.resourceIp);
  const regCode = useSessionStore((state) => state.registrationCode);
  const isLogUploaderEnabledAtDirectoryLevel = useSessionStore(
    (state) => state.isLogUploaderEnabledAtDirectoryLevel
  );

  const isLogUploaderEnabledByUser = useLogUploaderPreferenceStore(
    (state) => state.isLoggingEnabledByUser
  );
  const logUploaderLogLevel = useLogUploaderPreferenceStore(
    (state) => state.logLevelPreference
  );

  useEffect(() => {
    const wspContext = buildWebDcvInSessionConfigInput(
      isLogUploaderEnabledByUser,
      isLogUploaderEnabledAtDirectoryLevel,
      logUploaderLogLevel
    );

    logger.info('Starting WSP session streaming');
    window.sessionStorage.setItem(
      'wsp_context',
      btoa(JSON.stringify(wspContext))
    );
    const redirectPort = window.location.port ? `:${window.location.port}` : '';
    setInSessionUtilityParams(
      redirectPort,
      resourceId,
      resourceName,
      resourceComputeType,
      resourceIp,
      regCode as string
    );

    window.location.assign(
      `https://${window.location.hostname}${redirectPort}/wsp.html`
    );
  }, [sessionContext]);

  return <ConnectStatus />;
};

/*
 This function sets the workspace details in storage. This will be used in session for the toolbar: 'About My Workspace'
*/
const setInSessionUtilityParams = (
  port: string,
  id: string,
  name: string,
  type: string,
  ip: string,
  regCode: string
) => {
  window.sessionStorage.setItem(
    'return_url',
    `https://${window.location.hostname}${port}/disconnect`
  );
  window.localStorage.setItem('workspaceId', id);
  window.localStorage.setItem('workspaceName', name);
  window.localStorage.setItem('workspaceComputeType', type);
  window.localStorage.setItem('workspaceIp', ip);
  window.localStorage.setItem('registrationCode', regCode);
};

export default Maxibon;
