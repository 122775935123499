import { AuthStrategy, AuthType } from '@bridge/types/SoloRTCChannelTypes';

export const AppConstants = {
  WEB_CLIENT_APP_VERSION: 'web_5.0.0',
  WEB_CLIENT_WSP_PROTOCOL_VERSION: '1.3.0.171',
  COOKIE_CSRF_TOKEN: 'csrf-token',
  ERROR_URL_CLIENT_ERROR_CODE_SEARCH_PARAM_KEY: 'client_error_code',
  ERROR_URL_SOLO_ERROR_CODE_SEARCH_PARAM_KEY: 'solo_error_code',
  AUTH_WARP_DRIVE_CREDENTIAL: {
    AuthType: AuthType.WARP_DRIVE,
    AuthStrategy: AuthStrategy.CREDENTIAL,
  },
  AUTH_WARP_DRIVE_MFA: {
    AuthType: AuthType.WARP_DRIVE,
    AuthStrategy: AuthStrategy.MFA,
  },
  AUTH_FIPS_CERTIFICATE: {
    AuthType: AuthType.FIPS,
    AuthStrategy: AuthStrategy.CERTIFICATE,
  },
  DEFAULT_WEB_AUTH_CAPABILITIES: [],
};
