import logo from '@img/ws-logo.png';
import { IRegion, EndPointType } from './RegionTypes';
import { SessionState } from '@bridge/types/SoloRTCChannelTypes';
import {
  AuthMethod,
  DomainProperties,
  IAllocateResourceSuccessResponse,
  IResource,
  IResourceType,
  IToken,
  SessionContext,
} from '@/core/wsbroker/types';
import { DCVInSessionMetricsConfigInput } from '@bridge/types/MetricTypes';

export enum SessionProtocols {
  PCOIP = 'PCoIP',
  WSP = 'Highlander',
  // TODO: Remove and replace the MAXIBON enum after Pwd ReArch deployment
  MAXIBON = 'Maxibon',
  DCV = 'DCV',
}

export enum SessionLoginType {
  Username = 'Username',
  SmartCard = 'SmartCard',
}

export interface SessionDetails {
  loginType: SessionLoginType;
  directoryId: string;
  domainName: string;
  authBlob: any;
  registrationCode?: string;
  previousRegistrationCode?: string;
  logo: string;
  legalText?: string;
  supportUrl?: string;
  isCobrandingEnabled: boolean;
  isRememberMeEnabled: boolean;
  isLogUploaderEnabledAtDirectoryLevel: boolean;
  isNegotiationComplete: boolean;
  allocatedResource?: IAllocateResourceSuccessResponse;
  resourceId: string;
  resourceName: string;
  resourceIp: string;
  resourceComputeType: string;
  resourceType: IResourceType;
  resourceProtocol: SessionProtocols | '';
  resourceSessionContext: any;
  sessionState: SessionState;
  skipPlatformCheck: boolean;
  resource: IResource | undefined;
  region: IRegion | undefined;
  resourcePreloaded: boolean;
  // authentication section
  sessionContext: SessionContext | undefined;
  nextAuthMethod: AuthMethod | undefined;
  primaryAuthMethod: AuthMethod | undefined;
  fallbackAuthMethod: AuthMethod | undefined;
  domainProperties: DomainProperties | undefined;
  authToken: IToken | undefined;
  codeVerifier: string | undefined;
  // heartbeat section
  heartBeatEndpoint?: string;
  heartBeatSessionId?: string;
  HeartBeatContext: {
    intervalInSeconds: number;
  };
  httpsSettings: HttpsSettings;
}

export interface HttpsSettings {
  brokerEndpointType: EndPointType;
  clientServiceEndpointType: EndPointType;
  diagnosticsEndpointType: EndPointType;
  healthCheckEndpointType: EndPointType;
}

export interface SessionActions {
  setSession: (data: Partial<SessionDetails>) => void;
  resetSession: () => void;
  purgeSession: () => void;
}

export const initState: SessionDetails = {
  loginType: SessionLoginType.Username,
  directoryId: '',
  domainName: '',
  authBlob: undefined,
  registrationCode: undefined,
  logo,
  isCobrandingEnabled: false,
  isRememberMeEnabled: false,
  isLogUploaderEnabledAtDirectoryLevel: false,
  isNegotiationComplete: false,
  allocatedResource: undefined,
  resourceId: '',
  resourceName: '',
  resourceIp: '',
  resourceComputeType: '',
  resourceType: IResourceType.WORKSPACE,
  resourceProtocol: '',
  resourceSessionContext: {},
  sessionState: SessionState.NEGOTIATION,
  skipPlatformCheck: true,
  resource: undefined,
  region: undefined,
  resourcePreloaded: false,
  // authentication section
  sessionContext: undefined,
  nextAuthMethod: undefined,
  primaryAuthMethod: undefined,
  fallbackAuthMethod: undefined,
  domainProperties: undefined,
  authToken: undefined,
  codeVerifier: undefined,
  // heartbeat section
  heartBeatEndpoint: undefined,
  heartBeatSessionId: undefined,
  HeartBeatContext: {
    intervalInSeconds: 0,
  },
  httpsSettings: {
    brokerEndpointType: EndPointType.IPv4,
    clientServiceEndpointType: EndPointType.IPv4,
    diagnosticsEndpointType: EndPointType.IPv4,
    healthCheckEndpointType: EndPointType.IPv4,
  },
};

export interface IMaxibonContext {
  SessionId: string;
  AuthToken: string;
  ExtendedServerUrls?: string;
  ServerUrl: string;
  ExtensionData: string;
}

export interface IPcoIPWebContext {
  StxHDCredentials: any;
}

export interface IPcoIPContext {
  SsgGateways: any;
}

export type IStreamingSessionContext =
  | IPcoIPContext
  | IPcoIPWebContext
  | IMaxibonContext
  | undefined;

export interface IAllocateResourceRetryInfo {
  allocationRetryTimeout: number;
  allocateRetryInterval: number;
}

export enum IActionResultType {
  SUCCESS = 'SUCCESS_REDIRECT',
  RETRY = 'PENDING_RETRY',
}

export interface IAllocatedResourceSuccess {
  RawResponse: any;
  resultType: IActionResultType.SUCCESS;
  sessionContext: IStreamingSessionContext;
  sessionProtocol: SessionProtocols;
}

export interface IAllocatedResourceRetry {
  resultType: IActionResultType.RETRY;
  retryInfo: IAllocateResourceRetryInfo;
}

export type IAllocatedResource =
  | IAllocatedResourceSuccess
  | IAllocatedResourceRetry;

export type IProvisionedResource = IAllocatedResourceSuccess & {
  resourceId: string;
  resourceComputeType: string;
  resourceIp: string;
  resourceName: string;
};

export interface SessionProvisionInput {
  authToken: string;
  sessionId: string;
  regCode: string;
  region: IRegion;
}

export interface DCVWebInSessionConfigInput {
  broker_session_id: string;
  session_id: string;
  auth_token: string;
  server_url: string;
  extended_server_endpoints: any;
  extension_data: any;
  device_id: string;
  is_log_uploader_enabled: boolean;
  log_uploader_log_level: string;
  log_uploader_region: string;
  org_name: string;
  session_token: string;
  eucsso_report_heartbeat_endpoint: string;
  eucsso_report_heartbeat_session_id: string;
  metrics_config_input: DCVInSessionMetricsConfigInput;
  resource_type: IResourceType;
}
